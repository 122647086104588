<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="상속" tabActive="상속 회복 청구" bg="/images/sub/visual/sv-inheritance.jpg" />
        </template>
        <page-section dark tit="상속회복청구" titAdd="Inheritance" style="background-image: url(/images/sub/inheritance/claim/claim-bg.jpg)">
            <card-primary>
                진정한 상속인이 외관상 진정한 상속인으로 보이는 사람 즉, 참칭상속인을 상대로 상속재산의 반환, 회복을 청구하는 소송입니다. <br class="d-none d-xl-block">
                예를 들어 상속인이 아닌데 사망자(피상속인)의 가족관계등록부상 상속인으로 기재되어 있는 등 <br class="d-none d-xl-block">
                상속인처럼 권리를 행사하는 자로부터 자신의 상속권을 되찾는 소송이라 합니다.
            </card-primary>
        </page-section>
        <page-section tit="상속회복청구의 절차" titAdd="Inheritance" class="page-section-inheritance">
            <div class="card-rows">
                <card-row v-for="item in process" :key="item.txt" :image="item.image" :txt="item.txt" :caption="item.caption" />
            </div>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";
import CardRow from "@/sets/styles/card-row.vue";
import CardPrimary from "@/components/publish/parents/cards/card-primary.vue";

import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    props: {},
    components: {
        ClientPage,
        SubVisual,
        PageSection,
        CardRow,
        CardPrimary,

        Tit,
        Txt,
    },
    data() {
        return {            
            process : [
                {
                    txt : "진정한 상속인이 참칭상속인을 상대로 청구",
                    caption : "* 관할 법원은 피고의 주소 또는 부동산의 소재지 민사법원",
                    image : "/images/sub/inheritance/claim/claim-img.jpg",
                },
                {
                    txt : "상속회복청구권은 상속인이 상속재산의 침해를 안 날로부터 3년, 상속침해가 있는 날로부터 10년 이내에 청구하지 않으면 그 권리가 소멸",
                    image : "/images/sub/inheritance/claim/claim-img2.jpg",
                },
            ],
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.page-section-inheritance{
    background-image: url(/images/sub/inheritance/inheritance-bg.svg);
    background-size: 100% auto;
    background-position: center bottom
}
</style>
