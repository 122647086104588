var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "미디어 속 태성",
            "bg": "/images/sub/visual/sv-media.jpg"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('page-section', {
    staticClass: "page-section--media",
    attrs: {
      "tit": "미디어 속 태성",
      "titAdd": "Media"
    }
  }, [_c('v-divider'), _c('div', {
    staticClass: "media-area pt-30px pt-md-60px"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "data-aos": "fade-up",
      "cols": "12",
      "md": "5"
    }
  }, [_c('tit', [_vm._v(" 대한변호사협회 등록 "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v(" 이혼전문변호사 최유나 ")])], 1), _c('v-col', {
    attrs: {
      "data-aos": "fade-up",
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "1032",
      "aspect-ratio": 1032 / 580
    }
  }, [_c('iframe', {
    staticClass: "w-100 h-100 d-block",
    attrs: {
      "src": `https://www.youtube.com/embed/${_vm.selectedVideo}`,
      "frameborder": "0",
      "allow": "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
      "allowfullscreen": ""
    }
  })])], 1)], 1), _c('v-divider', {
    staticClass: "mt-30px mt-md-60px mb-40px mb-md-80px"
  }), _c('v-row', {
    staticClass: "my-md-n30px",
    attrs: {
      "data-aos": "fade-up"
    }
  }, _vm._l(_vm.media, function (item) {
    return _c('v-col', {
      key: item.title,
      staticClass: "py-md-30px",
      attrs: {
        "cols": "6",
        "md": "4"
      }
    }, [_c('v-card', {
      staticClass: "media-card v-card--none-active-bg",
      attrs: {
        "tile": "",
        "color": "transparent"
      },
      on: {
        "click": function ($event) {
          return _vm.changeVideoAndScroll(item);
        }
      }
    }, [_c('div', {
      staticClass: "media-card__thumb w-100"
    }, [_c('v-img', {
      attrs: {
        "src": `/images/media/${item.image}`,
        "max-width": "464",
        "aspect-ratio": 464 / 260
      }
    }), _c('btn-arrow-secondary', {
      staticClass: "media-card__btn",
      attrs: {
        "color": "white"
      }
    })], 1), _c('txt', {
      staticClass: "txt--dark text-truncate mt-12px mt-md-24px"
    }, [_vm._v(" " + _vm._s(item.title) + " ")])], 1)], 1);
  }), 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }