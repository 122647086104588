<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="이혼" tabActive="협의이혼">
                <template #video>
                    <video src="/images/sub/visual/sv-divorce.mp4" poster="/images/sub/visual/sv-divorce.jpg" loop autoplay muted playsinline />
                </template>
            </sub-visual>
        </template>
        <page-section dark tit="협의이혼" titAdd="Divorce" style="background-image: url(/images/sub/divorce/agreement/agreement-bg.jpg)">
            <card-primary>
                <strong class="primary--text">이혼은 크게 재판이혼과 협의이혼으로 나눌 수 있습니다.</strong> <br />
                협의이혼이란 당사자 간에 이혼, 양육권, 양육비에 대해 의사 합치가 있을 경우 <br class="d-none d-xl-block">
                가정법원의 확인을 받아 행정기관에 신고함으로써 효력이 발생하는 것을 의미합니다.
            </card-primary>
        </page-section>
        <page-section tit="협의이혼의 절차" titAdd="Divorce" class="page-section-divorce">
            <div class="card-rows">
                <card-row v-for="(item, index) in process" :key="index" :image="item.image" :title="'Step 0'+(index+1)" :txt="item.txt" />
            </div>
        </page-section>
        <page-section dark tit="협의이혼 시 위자료, 재산분할은?" titAdd="Divorce" gridSectionBg="/images/sub/divorce/agreement/agreement-bg2.jpg" gridSectionRightBg="/images/sub/divorce/agreement/agreement-bg2-mo.jpg">
            <template #gridSectionRight>
                <div class="h-100 d-flex align-center">
                    <txt class="white--text line-height-17">
                        <p>협의이혼에서 확인을 받는 사항은 미성년자녀가 있는 경우에는 이혼의사, 양육권, 양육비, 미성년자녀가 없거나 성인자녀를 둔 경우에는 이혼의사뿐입니다. </p>
                        <p class="mt-10px mt-md-16px">따라서 위자료&재산분할은 협의이혼을 할 경우라도 별도의 재판을 통하여 다툴 수 있습니다.</p>
                    </txt>         
                </div>
            </template>
        </page-section>
        <page-section style="background-image: url(/images/sub/divorce/agreement/agreement-bg3.jpg)">
            <tit-wrap-secondary>
                협의이혼 시 고려할 점
                <template #TitAdd>
                    Divorce
                </template>
                <template #TxtAdd>
                    많은 분들이 협의이혼을 하고 나면 위자료, 재산분할 청구를 할 수 없는 것으로 잘못 인식하고 계십니다. <br>
                    그러나 협의이혼을 한 이후에도 위자료, 재산분할청구가 얼마든지 가능하기 때문에 무턱대고 협의이혼을 하기보다는 이혼과 관련한 모든 사항에 <br class="d-none d-xl-block">
                    대하여 의사의 합치가 이루어졌는지 충분히 고려해야 합니다.
                </template>
            </tit-wrap-secondary>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";
import CardRow from "@/sets/styles/card-row.vue";
import CardPrimary from "@/components/publish/parents/cards/card-primary.vue";
import TitWrapSecondary from "@/components/publish/parents/typography/tit-wrap-secondary.vue";

import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    props: {},
    components: {
        ClientPage,
        SubVisual,
        PageSection,
        CardRow,
        CardPrimary,
        TitWrapSecondary,

        Tit,
        Txt,
    },
    data() {
        return {            
            process : [
                {
                    txt : "부부가 서로 이혼에 합의",
                    image : "/images/sub/divorce/agreement/agreement-img.jpg",
                },
                {
                    txt : "가정법원에 협의이혼 의사확인 신청",
                    image : "/images/sub/divorce/agreement/agreement-img2.jpg",
                },
                {
                    txt : "관련 안내 받은 날로부터 일정한 기간 (미성년 자녀가 있는 경우 3개월, 없는 경우 1개월)이 지난 뒤 최종적으로 판사 앞에서 이혼의사를 확인",
                    image : "/images/sub/divorce/agreement/agreement-img3.jpg",
                },
                {
                    txt : "관할 구청에 신고",
                    image : "/images/sub/divorce/agreement/agreement-img4.jpg",
                },
            ],
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.page-section-divorce{
    background-image: url(/images/sub/divorce/divorce-bg.svg);
    background-size: 100% auto;
}
</style>
