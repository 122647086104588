<template>
    <div class="sub-head">
        <div class="sub-visual grey darken-4">
            <div class="sub-visual__bg">
                <div v-if="this.$slots['video']" data-aos="zoom-out" data-aos-duration="1000" class="sub-visual__bg__inner sub-visual__bg__inner--video"><slot name="video" /></div>                
                <div v-else data-aos="zoom-out" data-aos-duration="1000" class="sub-visual__bg__inner" :style="'background-image:url(' + bg + ');'"></div>
            </div>
            <v-container>
                <div class="sub-visual__tit-wrap" :class="{ 'mb-0': !tabActive }">
                    <tit class="sub-visual__tit tit--xxl white--text overflow-hidden">
                        <span data-aos="fade-up" data-aos-delay="600" class="d-block">{{ sh }}</span>
                    </tit>
                    <div data-aos="zoom-out" data-aos-delay="1100" class="icon-rounded-object-wrap">
                        <icon-rounded-object color="white--text"/>
                    </div>
                </div>
                <div v-if="tabActive" class="overflow-hidden">
                    <client-gnb v-if="$vuetify.breakpoint.mdAndUp" className="sub-tab" data-aos="fade-up" data-aos-delay="700" :tabActive="tabActive" :sh="sh" />
                </div>
            </v-container>
        </div>
        <client-gnb v-if="!$vuetify.breakpoint.mdAndUp && tabActive" className="sub-tab" :tabActive="tabActive" :sh="sh" />
    </div>
</template>

<script>
import ClientGnb from "@/components/client/inc/client-gnb.vue";
import IconRoundedObject from "@/components/publish/styles/icons/icon-rounded-object.vue";
import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    props: {
        sh: { type: String, default: "" },
        bg: { type: String, default: "" },
        tabActive: { type: String, default: "" },
    },
    components: {
        ClientGnb,
        IconRoundedObject,
        Tit,
        Txt,
    },
    data: () => {
        return {};
    },
    mounted() {
        this.init();

        this.handleWheel = (event) => {
            var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            var windowHeight = window.innerHeight || document.documentElement.clientHeight;
            var tabletWidth = 767;
            var scrollPosition = 224;
            if (window.innerWidth > tabletWidth) {
                scrollPosition = windowHeight;
            }

            if (scrollTop < scrollPosition && event.deltaY > 0){
                window.removeEventListener('wheel', this.handleWheel);
                this.smoothScrollTo(scrollPosition, 800);
            } else if (scrollTop < scrollPosition && event.deltaY < 0){
                window.removeEventListener('wheel', this.handleWheel);
                this.smoothScrollTo(0, 800);
            }
        };

        window.addEventListener('wheel', this.handleWheel, { passive: false });
    },
    destroyed() {
        window.removeEventListener('wheel', this.handleWheel);
    },  
	methods : {
		init : function() {
            setTimeout(() => {
                AOS.init({
                    once: true,
                    offset: 0,
                    duration: 500,
                    throttleDelay : 100,
                    easing : 'ease-out',
                    anchorPlacement : 'top-center',
                });
            }, 100);
		},     
        smoothScrollTo(to, duration) {
            const start = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            const change = to - start;
            const increment = 20;
            let currentTime = 0;

            const animateScroll = () => {
                currentTime += increment;
                const val = this.easeInOutQuad(currentTime, start, change, duration);
                window.scrollTo(0, val);
                if (currentTime < duration) {
                    requestAnimationFrame(animateScroll);
                } else {
                    window.addEventListener('wheel', this.handleWheel, { passive: false });
                }
            };

            this.easeInOutQuad = function(t, b, c, d) {
                t /= d / 2;
                if (t < 1) return c / 2 * t * t + b;
                t--;
                return -c / 2 * (t * (t - 2) - 1) + b;
            };

            requestAnimationFrame(animateScroll);
        },
	},
};
</script>

<style lang="scss" scoped>
/* sub-visual */
.sub-visual {
    overflow: hidden;
    position:relative;
    display: flex;
    align-items: center;
    text-align: center;
    height: 280px;
    ::v-deep{
        .container{
            position: relative;
        }
    }
}
.sub-visual__tit-wrap {
    position: relative;
    width: fit-content;
    margin: 0 auto;
    text-align: center;
    .icon-rounded-object-wrap{
        width: fit-content;
        height: auto;
        position: absolute;
        right: -40px;
        top: -24px;
    }
    .icon-rounded-object{
        position: relative;
        opacity: .6;
    }
}
.sub-visual__bg {
    position: absolute;
    top:0;
    left: 50%;
    z-index: 0;
    -webkit-transform: translate(-50%,0); -ms-transform: translate(-50%,0); transform: translate(-50%,0);
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    &__inner{
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        &--video{
            ::v-deep{
                video{
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    position: relative;
                    &[poster]{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
            &::after{
                content: "";
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-color: rgba(0, 0, 0, .5);
            }
        }
    }
}
.sub-tab{
    background-color: var(--v-secondary-base);
    ::v-deep{
        >.container{
            padding: 0;
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .sub-visual {
        height: 100vh;
    }
    .sub-tab{
        background-color: transparent;
        ::v-deep{
            >.container{
                padding: var(--container-gutter);
            }
        }
    }
    .sub-visual__tit-wrap {
        margin-bottom: var(--tit-wrap-padding-bottom-lg);
        .icon-rounded-object-wrap{
            right: -120px;
            top: -45px;
        }
    }
}
@media (min-width: 1024px) {
    .sub-visual__tit-wrap {
        margin-bottom: var(--tit-wrap-padding-bottom-lg);
        .icon-rounded-object-wrap{
            right: -120px;
            top: -34px;
        }
    }
}
@media (min-width: 1200px) {
}
</style>
