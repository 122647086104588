var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "이혼",
            "tabActive": "협의이혼"
          },
          scopedSlots: _vm._u([{
            key: "video",
            fn: function () {
              return [_c('video', {
                attrs: {
                  "src": "/images/sub/visual/sv-divorce.mp4",
                  "poster": "/images/sub/visual/sv-divorce.jpg",
                  "loop": "",
                  "autoplay": "",
                  "muted": "",
                  "playsinline": ""
                },
                domProps: {
                  "muted": true
                }
              })];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section', {
    staticStyle: {
      "background-image": "url(/images/sub/divorce/agreement/agreement-bg.jpg)"
    },
    attrs: {
      "dark": "",
      "tit": "협의이혼",
      "titAdd": "Divorce"
    }
  }, [_c('card-primary', [_c('strong', {
    staticClass: "primary--text"
  }, [_vm._v("이혼은 크게 재판이혼과 협의이혼으로 나눌 수 있습니다.")]), _vm._v(" "), _c('br'), _vm._v(" 협의이혼이란 당사자 간에 이혼, 양육권, 양육비에 대해 의사 합치가 있을 경우 "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 가정법원의 확인을 받아 행정기관에 신고함으로써 효력이 발생하는 것을 의미합니다. ")])], 1), _c('page-section', {
    staticClass: "page-section-divorce",
    attrs: {
      "tit": "협의이혼의 절차",
      "titAdd": "Divorce"
    }
  }, [_c('div', {
    staticClass: "card-rows"
  }, _vm._l(_vm.process, function (item, index) {
    return _c('card-row', {
      key: index,
      attrs: {
        "image": item.image,
        "title": 'Step 0' + (index + 1),
        "txt": item.txt
      }
    });
  }), 1)]), _c('page-section', {
    attrs: {
      "dark": "",
      "tit": "협의이혼 시 위자료, 재산분할은?",
      "titAdd": "Divorce",
      "gridSectionBg": "/images/sub/divorce/agreement/agreement-bg2.jpg",
      "gridSectionRightBg": "/images/sub/divorce/agreement/agreement-bg2-mo.jpg"
    },
    scopedSlots: _vm._u([{
      key: "gridSectionRight",
      fn: function () {
        return [_c('div', {
          staticClass: "h-100 d-flex align-center"
        }, [_c('txt', {
          staticClass: "white--text line-height-17"
        }, [_c('p', [_vm._v("협의이혼에서 확인을 받는 사항은 미성년자녀가 있는 경우에는 이혼의사, 양육권, 양육비, 미성년자녀가 없거나 성인자녀를 둔 경우에는 이혼의사뿐입니다. ")]), _c('p', {
          staticClass: "mt-10px mt-md-16px"
        }, [_vm._v("따라서 위자료&재산분할은 협의이혼을 할 경우라도 별도의 재판을 통하여 다툴 수 있습니다.")])])], 1)];
      },
      proxy: true
    }])
  }), _c('page-section', {
    staticStyle: {
      "background-image": "url(/images/sub/divorce/agreement/agreement-bg3.jpg)"
    }
  }, [_c('tit-wrap-secondary', {
    scopedSlots: _vm._u([{
      key: "TitAdd",
      fn: function () {
        return [_vm._v(" Divorce ")];
      },
      proxy: true
    }, {
      key: "TxtAdd",
      fn: function () {
        return [_vm._v(" 많은 분들이 협의이혼을 하고 나면 위자료, 재산분할 청구를 할 수 없는 것으로 잘못 인식하고 계십니다. "), _c('br'), _vm._v(" 그러나 협의이혼을 한 이후에도 위자료, 재산분할청구가 얼마든지 가능하기 때문에 무턱대고 협의이혼을 하기보다는 이혼과 관련한 모든 사항에 "), _c('br', {
          staticClass: "d-none d-xl-block"
        }), _vm._v(" 대하여 의사의 합치가 이루어졌는지 충분히 고려해야 합니다. ")];
      },
      proxy: true
    }])
  }, [_vm._v(" 협의이혼 시 고려할 점 ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }