
<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="태성 이혼가사센터 성공사례" bg="/images/sub/visual/sv-reference.jpg" />
        </template>
        <page-section>
            <reference-view />
        </page-section>
    </client-page>
</template>

<script>
import api from "@/api"
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";
import ReferenceView from "@/components/client/reference/reference-view.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
        PageSection,
        ReferenceView
    },
    data() {
        return {
            board: {}
        };
    },
    async mounted() {
		await this.init();
	},
    methods: {
		async init() {
			let { board } = await api.v1.boards.get({ _id: this.$route.params._board })
			this.board = board;
		}
	},
};
</script>

<style lang="scss" scoped>
.row {
    margin: -6px;
    [class*="col"] {
        padding: 6px;
    }
}
</style>
