<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="이혼" tabActive="이혼소송">
                <template #video>
                    <video src="/images/sub/visual/sv-divorce.mp4" poster="/images/sub/visual/sv-divorce.jpg" loop autoplay muted playsinline />
                </template>
            </sub-visual>
        </template>
        <page-section dark tit="이혼소송" titAdd="Divorce" style="background-image: url(/images/sub/divorce/lawsuit/lawsuit-bg.jpg)">
            <card-primary>
                <strong class="primary--text">합의점을 찾기 위해 최선을 다 해보셨나요?</strong> <br />
                소송은 협의가 불가능할 경우에만 하는 것입니다. 합의단계부터 변호사와 상담하셔야 <br class="d-none d-xl-block">
                포기하는 마음으로 하는 합의가 아닌 나에게 최선이 되는 협상을 할 수 있습니다.
            </card-primary>
        </page-section>
        <page-section tit="재판상이혼" titAdd="Divorce" class="page-section-divorce">
            <div class="card-rows">
                <card-row
                image="/images/sub/divorce/lawsuit/lawsuit-img.jpg"
                txt="이혼소송 즉, 재판상 이혼이란 이혼의 합의가 이루어지지 않는 경우 법원의 판결 또는 조정에 의해 이혼의 효과가 발생하는 것을 의미합니다. 재판상 이혼을 청구하기 위해서는 민법 제840조에서 정하는 이혼사유에 해당하는 경우여야 합니다." />
            </div>
        </page-section>
        <page-section dark tit="재판상이혼 절차" titAdd="Divorce" style="background-image: url(/images/sub/divorce/lawsuit/lawsuit-bg2.jpg)">
            <v-img data-aos="fade-up" src="/images/sub/divorce/lawsuit/lawsuit-img2.svg" max-width="1440" :aspect-ratio="1440 / 640" contain class="w-100 d-none d-md-block" />
            <v-img data-aos="fade-up" src="/images/sub/divorce/lawsuit/lawsuit-img2-mo.svg" max-width="336" :aspect-ratio="336 / 2340" contain class="w-100 mx-auto d-md-none" />
        </page-section>
        <page-section tit="재판상이혼 사유" titAdd="Divorce" gridSectionBg="/images/sub/divorce/lawsuit/lawsuit-bg3.jpg" gridSectionRightBg="/images/sub/divorce/lawsuit/lawsuit-bg3-mo.jpg">
            <template #gridSectionRight>
                <div class="cards">
                    <card-secondary v-for="detail in reason" :key="detail">{{detail}}</card-secondary>
                </div>
            </template>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";
import CardRow from "@/sets/styles/card-row.vue";
import CardPrimary from "@/components/publish/parents/cards/card-primary.vue";
import CardSecondary from "@/components/publish/parents/cards/card-secondary.vue";

import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    props: {},
    components: {
        ClientPage,
        SubVisual,
        PageSection,
        CardRow,
        CardPrimary,
        CardSecondary,

        Tit,
        Txt,
    },
    data() {
        return {                        
            reason : [
                "배우자의 부정행위 (외도)",
                "배우자의 악의의 유기 (동거, 부양 등의 배우자로서의 의무를 불이행하는 경우)",
                "배우자와 그 부모로부터 부당한 대우를 받은 경우 (배우자와 그의 부모가 신체적, 정신적으로 고통을 주는 경우)",
                "배우자가 자신의 부모에 대해 부당한 대우를 한 경우",
                "배우자의 생사가 3년 이상 불분명한 경우",
                "그 밖에 혼인을 계속하기 어려운 중대한 사유 (위의 5가지 경우에 해당하지 않는다고 하더라도 6호에 기타규정을 둠으로써 이혼사유를 좀 더 넓게 인정하고 있습니다.)",
            ],
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.page-section-divorce{
    background-image: url(/images/sub/divorce/divorce-bg.svg);
    background-size: 100% auto;
    background-position: center bottom
}
</style>
