var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "상속",
            "tabActive": "상속 회복 청구",
            "bg": "/images/sub/visual/sv-inheritance.jpg"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('page-section', {
    staticStyle: {
      "background-image": "url(/images/sub/inheritance/claim/claim-bg.jpg)"
    },
    attrs: {
      "dark": "",
      "tit": "상속회복청구",
      "titAdd": "Inheritance"
    }
  }, [_c('card-primary', [_vm._v(" 진정한 상속인이 외관상 진정한 상속인으로 보이는 사람 즉, 참칭상속인을 상대로 상속재산의 반환, 회복을 청구하는 소송입니다. "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 예를 들어 상속인이 아닌데 사망자(피상속인)의 가족관계등록부상 상속인으로 기재되어 있는 등 "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 상속인처럼 권리를 행사하는 자로부터 자신의 상속권을 되찾는 소송이라 합니다. ")])], 1), _c('page-section', {
    staticClass: "page-section-inheritance",
    attrs: {
      "tit": "상속회복청구의 절차",
      "titAdd": "Inheritance"
    }
  }, [_c('div', {
    staticClass: "card-rows"
  }, _vm._l(_vm.process, function (item) {
    return _c('card-row', {
      key: item.txt,
      attrs: {
        "image": item.image,
        "txt": item.txt,
        "caption": item.caption
      }
    });
  }), 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }