var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card-row-wrap"
  }, [_c('v-row', {
    staticClass: "row--xl ma-xl-n50px card-row",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-xl-50px",
    attrs: {
      "data-aos": "fade-up",
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    staticClass: "w-100",
    attrs: {
      "rounded": "sm",
      "max-width": "670"
    }
  }, [_c('v-img', {
    staticClass: "w-100",
    attrs: {
      "src": _vm.image,
      "aspect-ratio": 670 / 446
    }
  })], 1)], 1), _c('v-col', {
    staticClass: "pa-xl-50px",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "100",
      "cols": "12",
      "md": "6"
    }
  }, [_c('tit', {
    staticClass: "line-height-17 font-secondary"
  }, [_vm._v(_vm._s(_vm.title))]), _vm.txt ? _c('txt', {
    staticClass: "txt--xl txt--dark line-height-17",
    class: {
      'mt-4px mt-md-8px': _vm.title
    }
  }, [_vm._v(_vm._s(_vm.txt))]) : _vm._e(), _vm.caption ? _c('txt', {
    staticClass: "txt--light line-height-17 mt-4px mt-md-8px"
  }, [_vm._v(_vm._s(_vm.caption))]) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }