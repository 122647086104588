var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "태성 이혼가사센터",
            "tabActive": "태성 이혼가사센터 소개",
            "bg": "/images/sub/visual/sv-about.jpg"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('page-section', {
    staticClass: "page-section--center",
    attrs: {
      "tit": "태성의 이혼가사센터",
      "titAdd": "법무법인 태성"
    }
  }, [_c('v-divider'), _vm._l(_vm.product, function (item) {
    return _c('div', {
      key: item.title
    }, [_c('card-row', {
      staticClass: "py-30px py-md-60px",
      attrs: {
        "image": item.image,
        "title": item.title,
        "txt": item.txt
      }
    }), _c('v-divider')], 1);
  })], 2), _c('page-section', {
    attrs: {
      "tit": "이혼전문변호사란?",
      "titAdd": "법무법인 태성",
      "gridSectionBg": "/images/sub/taesung/center/center-bg2.jpg",
      "gridSectionRightBg": "/images/sub/taesung/center/center-bg2-mo.jpg"
    }
  }, [_c('div', {
    attrs: {
      "slot": "gridSectionRight"
    },
    slot: "gridSectionRight"
  }, [_c('txt', {
    staticClass: "white--text line-height-17"
  }, [_vm._v(" 이혼전문변호사란 대한변호사협회 전문분야 심사위원회에서 당해변호사의 처리 이혼사건의 수, 관련논문, 학위, 강의, 외부 할동 경험등을 종합적으로 검토하여 등록을 마친 경우에만 사용할 수 있는 명칭입니다. 이러한 심사없이 이혼전문변호사라는 단어를 쓰게 되는 경우에는 대한변호사협회의 징계 대상이 됩니다. ")]), _c('span', {
    staticClass: "line my-10px my-md-16px"
  }), _c('tit', {
    staticClass: "tit--sm white--text"
  }, [_vm._v(" 이혼을 결심하셨다면 반드시 이혼전문변호사와 상담하셔서 "), _c('br'), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v("합리적인 해결책을 얻으시길 바랍니다.")])])], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }