var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page-section', {
    staticClass: "page-section--contact",
    attrs: {
      "tit": _vm.title,
      "titAdd": "Contact Us"
    }
  }, [_c('v-card', {
    staticClass: "mb-40px mb-md-80px",
    attrs: {
      "data-aos": "fade-up",
      "rounded": ""
    }
  }, [_c('iframe', {
    staticClass: "d-block",
    staticStyle: {
      "border": "0"
    },
    attrs: {
      "src": _vm.iframe,
      "width": "100%",
      "height": "600",
      "allowfullscreen": "",
      "loading": "lazy",
      "referrerpolicy": "no-referrer-when-downgrade"
    }
  })]), !_vm.$vuetify.breakpoint.smAndUp ? _c('v-divider') : _vm._e(), _c('v-row', {
    staticClass: "text-center",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    class: _vm.$vuetify.breakpoint.smAndUp ? 'border-r' : 'border-b',
    attrs: {
      "cols": "12",
      "sm": "4"
    }
  }, [_c('v-card', {
    staticClass: "v-card--none-active-bg",
    attrs: {
      "data-aos": "fade-up",
      "href": _vm.addressLink,
      "target": "_blank"
    }
  }, [_c('div', {
    staticClass: "pa-30px py-sm-0 px-lg-40px"
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "src": "/images/sub/contact/contact-icon.svg",
      "max-width": "60",
      "aspect-ratio": 1 / 1
    }
  }), _c('tit', {
    staticClass: "tit--sm font-secondary mt-12px mt-md-24px"
  }, [_vm._v("Address")]), _c('txt', {
    staticClass: "txt--dark mt-4px mt-md-8px"
  }, [_vm._v(_vm._s(_vm.address))])], 1)])], 1), _c('v-col', {
    class: _vm.$vuetify.breakpoint.smAndUp ? 'border-r' : 'border-b',
    attrs: {
      "cols": "12",
      "sm": "4"
    }
  }, [_c('v-card', {
    staticClass: "v-card--none-active-bg",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "100",
      "href": 'tel:' + _vm.tel,
      "target": "_blank"
    }
  }, [_c('div', {
    staticClass: "pa-30px py-sm-0 px-lg-40px"
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "src": "/images/sub/contact/contact-icon2.svg",
      "max-width": "60",
      "aspect-ratio": 1 / 1
    }
  }), _c('tit', {
    staticClass: "tit--sm font-secondary mt-12px mt-md-24px"
  }, [_vm._v("Tel")]), _c('txt', {
    staticClass: "txt--dark mt-4px mt-md-8px"
  }, [_vm._v(_vm._s(_vm.tel))])], 1)])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "4"
    }
  }, [_c('v-card', {
    staticClass: "v-card--none-active-bg",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_c('div', {
    staticClass: "pa-30px py-sm-0 px-lg-40px"
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "src": "/images/sub/contact/contact-icon3.svg",
      "max-width": "60",
      "aspect-ratio": 1 / 1
    }
  }), _c('tit', {
    staticClass: "tit--sm font-secondary mt-12px mt-md-24px"
  }, [_vm._v("Fax")]), _c('txt', {
    staticClass: "txt--dark mt-4px mt-md-8px"
  }, [_vm._v(_vm._s(_vm.fax))])], 1)])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }