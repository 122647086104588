<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="태성 이혼가사센터" tabActive="태성 이혼가사센터 소개" bg="/images/sub/visual/sv-about.jpg" />
        </template>
        <page-section tit="태성의 이혼가사센터" titAdd="법무법인 태성" class="page-section--center">
            <v-divider />
            <div v-for="item in product" :key="item.title">
                <card-row :image="item.image" :title="item.title" :txt="item.txt" class="py-30px py-md-60px" />
                <v-divider />
            </div>
        </page-section>
        <page-section tit="이혼전문변호사란?" titAdd="법무법인 태성" gridSectionBg="/images/sub/taesung/center/center-bg2.jpg" gridSectionRightBg="/images/sub/taesung/center/center-bg2-mo.jpg">
            <div slot="gridSectionRight">
                <txt class="white--text line-height-17">
                    이혼전문변호사란 대한변호사협회 전문분야 심사위원회에서 당해변호사의 처리 이혼사건의 수, 관련논문, 학위, 강의, 외부 할동 경험등을 종합적으로 검토하여 등록을 마친 경우에만
                    사용할 수 있는 명칭입니다. 이러한 심사없이 이혼전문변호사라는 단어를 쓰게 되는
                    경우에는 대한변호사협회의 징계 대상이 됩니다.
                </txt>
                <span class="line my-10px my-md-16px"></span>
                <tit class="tit--sm white--text">
                    이혼을 결심하셨다면 반드시 이혼전문변호사와 상담하셔서 <br>
                    <span class="primary--text">합리적인 해결책을 얻으시길 바랍니다.</span>
                </tit>
            </div>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";
import CardRow from "@/sets/styles/card-row.vue";

import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    props: {},
    components: {
        ClientPage,
        SubVisual,
        PageSection,
        CardRow,

        Tit,
        Txt,
    },
    data: () => {
        return {
            product : [
                {
                    title : "전문성",
                    txt : "이혼사건만 12년 · 3천 건 이상의 소송경험, 이혼가사전문센터 운영 및 1사건 2인 변호사 (이혼팀 전원 이혼전문등록) 체제",
                    image : "/images/sub/taesung/center/center-img.jpg",
                },
                {
                    title : "언론의 신뢰, 다양한 방송출연 및 자문",
                    image : "/images/sub/taesung/center/center-img2.jpg",
                },
                {
                    title : "황혼이혼 재산분할 특성화",
                    image : "/images/sub/taesung/center/center-img3.jpg",
                },
            ],
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.page-section--center{
    background-image: url(/images/sub/taesung/center/center-bg.svg);
    background-size: auto 100%;
    background-position: right bottom;
}
</style>
