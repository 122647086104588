<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="오시는 길" tabActive="서울이혼가사센터" bg="/images/sub/visual/sv-contact.jpg" />
        </template>
        <contact-info 
            title="서울이혼가사센터"
            iframe="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3166.104409759947!2d127.03461277635908!3d37.481862428978204!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca16c0c06ec01%3A0x43cc526dfc30b3a3!2z7J207Zi87KCE66y467OA7Zi47IKsIOuyleustOuyleyduCDtg5zshLE!5e0!3m2!1sko!2skr!4v1691649430271!5m2!1sko!2skr"
            address="서울 서초구 강남대로 196 구민빌딩 2층"
            addressLink="https://goo.gl/maps/VUhH2iNyGfukpJP39"
            tel="02-6952-9291"
            fax="02-3472-3162" />
        <contact-slide>
            <v-img src="/images/sub/contact/seoul/seoul-img.jpg" max-width="952" :aspect-ratio="952 / 634" class="w-100" />
            <!-- <swiper-slide v-for="detail in slide" :key="detail" class="swiper swiper--slide">
                <div class="slide-contents__right__inner">
                    <div class="slide-contents__right__image-wrap">
                        <div class="slide-contents__right__image">
                            <div class="slide-contents__right__image__inner image-card" style="background-image: url(/images/sub/contact/seoul/seoul-img.jpg)"></div>
                            <div class="slide-contents__right__image__inner image-card" :style="'background-image: url('+detail+')'"></div>
                        </div>
                    </div> 
                </div>
            </swiper-slide> -->
        </contact-slide>
    </client-page>
</template>

<script>
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";
import ContactInfo from "@/sets/styles/contact-info.vue";
import ContactSlide from "@/sets/styles/contact-slide.vue";

import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    props: {},
    components: {
        ClientPage,
        SubVisual,
        PageSection,
        ContactInfo,
        ContactSlide,

        Tit,
        Txt,
    },
    data: () => {
        return {            
            // slide : [
            //     "/images/sub/contact/seoul/seoul-img.jpg",
            //     "/images/sub/contact/incheon/incheon-img.jpg",
            // ]
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
</style>
