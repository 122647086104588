var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "이혼",
            "tabActive": "이혼소송"
          },
          scopedSlots: _vm._u([{
            key: "video",
            fn: function () {
              return [_c('video', {
                attrs: {
                  "src": "/images/sub/visual/sv-divorce.mp4",
                  "poster": "/images/sub/visual/sv-divorce.jpg",
                  "loop": "",
                  "autoplay": "",
                  "muted": "",
                  "playsinline": ""
                },
                domProps: {
                  "muted": true
                }
              })];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section', {
    staticStyle: {
      "background-image": "url(/images/sub/divorce/lawsuit/lawsuit-bg.jpg)"
    },
    attrs: {
      "dark": "",
      "tit": "이혼소송",
      "titAdd": "Divorce"
    }
  }, [_c('card-primary', [_c('strong', {
    staticClass: "primary--text"
  }, [_vm._v("합의점을 찾기 위해 최선을 다 해보셨나요?")]), _vm._v(" "), _c('br'), _vm._v(" 소송은 협의가 불가능할 경우에만 하는 것입니다. 합의단계부터 변호사와 상담하셔야 "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 포기하는 마음으로 하는 합의가 아닌 나에게 최선이 되는 협상을 할 수 있습니다. ")])], 1), _c('page-section', {
    staticClass: "page-section-divorce",
    attrs: {
      "tit": "재판상이혼",
      "titAdd": "Divorce"
    }
  }, [_c('div', {
    staticClass: "card-rows"
  }, [_c('card-row', {
    attrs: {
      "image": "/images/sub/divorce/lawsuit/lawsuit-img.jpg",
      "txt": "이혼소송 즉, 재판상 이혼이란 이혼의 합의가 이루어지지 않는 경우 법원의 판결 또는 조정에 의해 이혼의 효과가 발생하는 것을 의미합니다. 재판상 이혼을 청구하기 위해서는 민법 제840조에서 정하는 이혼사유에 해당하는 경우여야 합니다."
    }
  })], 1)]), _c('page-section', {
    staticStyle: {
      "background-image": "url(/images/sub/divorce/lawsuit/lawsuit-bg2.jpg)"
    },
    attrs: {
      "dark": "",
      "tit": "재판상이혼 절차",
      "titAdd": "Divorce"
    }
  }, [_c('v-img', {
    staticClass: "w-100 d-none d-md-block",
    attrs: {
      "data-aos": "fade-up",
      "src": "/images/sub/divorce/lawsuit/lawsuit-img2.svg",
      "max-width": "1440",
      "aspect-ratio": 1440 / 640,
      "contain": ""
    }
  }), _c('v-img', {
    staticClass: "w-100 mx-auto d-md-none",
    attrs: {
      "data-aos": "fade-up",
      "src": "/images/sub/divorce/lawsuit/lawsuit-img2-mo.svg",
      "max-width": "336",
      "aspect-ratio": 336 / 2340,
      "contain": ""
    }
  })], 1), _c('page-section', {
    attrs: {
      "tit": "재판상이혼 사유",
      "titAdd": "Divorce",
      "gridSectionBg": "/images/sub/divorce/lawsuit/lawsuit-bg3.jpg",
      "gridSectionRightBg": "/images/sub/divorce/lawsuit/lawsuit-bg3-mo.jpg"
    },
    scopedSlots: _vm._u([{
      key: "gridSectionRight",
      fn: function () {
        return [_c('div', {
          staticClass: "cards"
        }, _vm._l(_vm.reason, function (detail) {
          return _c('card-secondary', {
            key: detail
          }, [_vm._v(_vm._s(detail))]);
        }), 1)];
      },
      proxy: true
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }