var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "태성 이혼가사센터",
            "tabActive": "문종하 변호사 소개",
            "bg": "/images/sub/visual/sv-about.jpg"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('page-section', {
    staticClass: "lawyer-view__head",
    attrs: {
      "tit": "문종하 변호사 소개",
      "titAdd": "법무법인 태성",
      "titClass": "pb-0"
    },
    scopedSlots: _vm._u([{
      key: "pageSectionImmersive",
      fn: function () {
        return [_c('v-img', {
          staticClass: "text-bg w-100",
          attrs: {
            "src": "/images/sub/taesung/lawyer/lawyer-bg.svg",
            "max-width": "1840",
            "aspect-ratio": 1840 / 266
          }
        })];
      },
      proxy: true
    }])
  }), _c('page-section', {
    staticClass: "lawyer-view__body secondary"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "7"
    }
  }, [_c('tit', {
    staticClass: "tit--lg white--text",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_c('span', {
    staticClass: "primary--text"
  }, [_vm._v("당신의 새로운 시작에 함께 하겠습니다. ")])]), _c('span', {
    staticClass: "line my-12px my-md-24px",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "100"
    }
  }), _c('txt', {
    staticClass: "white--text line-height-17",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_c('p', {
    staticClass: "mb-12px mb-md-24px"
  }, [_vm._v("안녕하세요. 이혼전문변호사 문종하입니다.")]), _c('p', [_vm._v(" 언제나 의뢰인의 이야기에 귀를 기울이고 전문적인 도움을 드리겠습니다. 이혼소송은 각자의 상황에 맞는 해결책이 필요한 만큼 의뢰인의 상황에 적합한 최상의 결과를 만들어 드리겠습니다. 어렵고 힘든 시간을 지나 새로운 시작에 힘이 되어 드리겠습니다. ")])])], 1), _c('v-col', {
    attrs: {
      "cols": "8",
      "sm": "5",
      "offset": "4",
      "offset-sm": "7",
      "offset-md": "0"
    }
  }, [_c('div', {
    staticClass: "lawyer-img-wrap"
  }, [_c('v-img', {
    staticClass: "lawyer-img w-100",
    attrs: {
      "data-aos": "fade-left",
      "src": "/images/lawyer/view/문종하.png",
      "max-width": "586",
      "aspect-ratio": 586 / 900
    }
  })], 1)])], 1)], 1), _c('page-section', {
    staticClass: "lawyer-view__foot page-section--last page-section--sm"
  }, _vm._l(_vm.info, function (item, index) {
    return _c('info-row', {
      key: index,
      attrs: {
        "title": item.title,
        "topLine": index == 0
      }
    }, _vm._l(item.infoDetail, function (detail, index) {
      return _c('info-detail', {
        key: index,
        class: index !== 0 ? 'mt-10px mt-md-16px' : ''
      }, [_vm._v(" " + _vm._s(detail) + " ")]);
    }), 1);
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }