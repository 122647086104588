var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "상간자소송",
            "bg": "/images/sub/visual/sv-lawsuit.jpg"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('page-section', {
    staticStyle: {
      "background-image": "url(/images/sub/lawsuit/lawsuit-bg2.jpg)"
    },
    attrs: {
      "dark": "",
      "tit": "상간자소송",
      "titAdd": "Lawsuit"
    }
  }, [_c('card-primary', [_c('strong', {
    staticClass: "primary--text"
  }, [_vm._v("외도를 저지른 사람이 오히려 나에게 원인을 제공했다고 말하나요?")]), _vm._v(" "), _c('br'), _vm._v(" 혼인파탄의 책임을 스스로 느끼지 못한다면 법으로 알게 해 주세요. ")])], 1), _c('page-section', {
    staticClass: "page-section-lawsuit",
    attrs: {
      "tit": "위자료청구",
      "titAdd": "Lawsuit"
    }
  }, [_c('div', {
    staticClass: "card-rows"
  }, [_c('card-row', {
    attrs: {
      "image": "/images/sub/lawsuit/lawsuit-img.jpg",
      "txt": "혼인이 파탄에 이르게 된 원인이 상대방에게 있다면 이로 인한 정신적 고통에 대한 배상 즉, 위자료를 청구할 수 있습니다. 위자료청구는 이혼소송과 병합하여 진행되는 것이 일반적이지만 협의이혼 후 위자료청구만 별도로 진행하는 것도 가능합니다. 이 경우에는 협의이혼 후 3년 내에 청구할 수 있습니다."
    }
  })], 1)]), _c('page-section', {
    attrs: {
      "dark": "",
      "tit": "위자료액수 산정기준",
      "titAdd": "Lawsuit",
      "gridSectionBg": "/images/sub/lawsuit/lawsuit-bg3.jpg",
      "gridSectionRightBg": "/images/sub/lawsuit/lawsuit-bg3-mo.jpg"
    },
    scopedSlots: _vm._u([{
      key: "gridSectionRight",
      fn: function () {
        return [_c('div', {
          staticClass: "cards"
        }, _vm._l(_vm.standard, function (detail) {
          return _c('card-secondary', {
            key: detail
          }, [_vm._v(_vm._s(detail))]);
        }), 1)];
      },
      proxy: true
    }])
  }), _c('page-section', {
    staticStyle: {
      "background-image": "url(/images/sub/lawsuit/lawsuit-bg4.jpg)"
    }
  }, [_c('tit-wrap-secondary', {
    scopedSlots: _vm._u([{
      key: "TitAdd",
      fn: function () {
        return [_vm._v(" Lawsuit ")];
      },
      proxy: true
    }, {
      key: "TxtAdd",
      fn: function () {
        return [_c('p', [_vm._v(" 상대방이 부정행위를 저지른 경우 배우자와 그 상대에게 위자료를 청구하는 것도 가능하며, 배우자를 제외하고 내연남&내연녀를 상대로 하여 "), _c('br', {
          staticClass: "d-none d-xl-block"
        }), _vm._v(" 위자료 청구가 가능합니다. ")])];
      },
      proxy: true
    }])
  }, [_vm._v(" 내연남&내연녀를 상대로 한 위자료청구 ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }