<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="이혼" tabActive="양육권&친권">
                <template #video>
                    <video src="/images/sub/visual/sv-divorce.mp4" poster="/images/sub/visual/sv-divorce.jpg" loop autoplay muted playsinline />
                </template>
            </sub-visual>
        </template>
        <page-section dark tit="양육권&친권" titAdd="Divorce" style="background-image: url(/images/sub/divorce/custody/custody-bg.jpg)">
            <card-primary>
                <strong class="primary--text">양육권 다툼은 시작 전 준비가 관건입니다.</strong> <br />
                본격적인 다툼을 시작하기 전에 어떻게 하면 우리 아이의 일상을 내가 지켜 줄 수 있을지 <br class="d-none d-xl-block">
                미리 상담 받으세요. 싸움이 벌어지면 늦습니다.
            </card-primary>
        </page-section>
        <page-section tit="양육권 및 친권은 어떻게 정해지나?" titAdd="Divorce" class="page-section-divorce">
            <div class="card-rows">
                <card-row
                image="/images/sub/divorce/custody/custody-img.jpg"
                txt="재산은 분할할 수 있지만, 자녀의 양육은 분할이 어렵기 때문에 부부 간에 양육권 다툼이 있는 경우에는 그 어떤 소송보다 치열하게 진행됩니다. 친권도 주로 양육권자에게 함께 지정되고 비양육권자는 양육비지급의무가 있기 때문에 이혼소송에 있어 양육권자 지정은 큰 의미를 가집니다." />
            </div>
        </page-section>
        <page-section dark tit="양육권 지정 기준" titAdd="Divorce" gridSectionBg="/images/sub/divorce/custody/custody-bg2.jpg" gridSectionRightBg="/images/sub/divorce/custody/custody-bg2-mo.jpg">
            <template #gridSectionRight>                
                <div class="cards">
                    <card-secondary v-for="detail in standard" :key="detail">{{detail}}</card-secondary>
                </div>
            </template>
        </page-section>
        <page-section style="background-image: url(/images/sub/divorce/custody/custody-bg3.jpg)">
            <tit-wrap-secondary>
                가사조사절차에 대하여
                <template #TitAdd>
                    Divorce
                </template>
                <template #TxtAdd>
                    <p>
                        이러한 사항들을 면밀히 조사하기 위해 이혼소송 중 양육권 다툼이 있는 경우, 재판부에서 가사조사명령을 내리게 됩니다. 이 명령에 따라 <br class="d-none d-xl-block">
                        가사조사관들은 부부와의 면담을 통해서 양육권판단에 기준이 되는 요소들을 조사하게 됩니다.
                    </p>
                    <p class="mt-12px mt-md-24px">
                        양육권소송에 있어서의 핵심은 위 가사조사절차에서 당황하지 않고, 양육권 지정에 영향을 미치는 기준들을 잘 주장하고 소명해 내는 것입니다. <br class="d-none d-xl-block">
                        감정에만 호소하여 양육권 소송에서 패하는 경우가 굉장히 많기 때문에 양육권소송에서는 전문가의 도움이 꼭 필요합니다.
                    </p>
                </template>
            </tit-wrap-secondary>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";
import CardRow from "@/sets/styles/card-row.vue";
import CardPrimary from "@/components/publish/parents/cards/card-primary.vue";
import CardSecondary from "@/components/publish/parents/cards/card-secondary.vue";
import TitWrapSecondary from "@/components/publish/parents/typography/tit-wrap-secondary.vue";

import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    props: {},
    components: {
        ClientPage,
        SubVisual,
        PageSection,
        CardRow,
        CardPrimary,
        CardSecondary,
        TitWrapSecondary,

        Tit,
        Txt,
    },
    data() {
        return {                                   
            standard : [
                "부모의 성격",
                "아이와의 애착도",
                "소송 중 누가 아이를 양육하고 있는지의 여부",
                "아이의 성별과 나이",
                "양육환경",
            ],
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.page-section-divorce{
    background-image: url(/images/sub/divorce/divorce-bg.svg);
    background-size: 100% auto;
    background-position: center bottom
}
</style>
