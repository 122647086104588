<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="오시는 길" tabActive="인천이혼가사센터" bg="/images/sub/visual/sv-contact.jpg" />
        </template>
        <contact-info 
            title="인천이혼가사센터"
            iframe="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3166.9286651850134!2d126.68523648892224!3d37.46240615807739!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357b795c00e3a849%3A0xd44ce7af3b2289d6!2z67KV66y067KV7J247YOc7ISx!5e0!3m2!1sko!2skr!4v1691657442002!5m2!1sko!2skr"
            address="인천시 미추홀구 경원대로 892, 대동빌딩 1층 (주안동)"
            addressLink="https://goo.gl/maps/M5sStAuYzPDBtCZx9"
            tel="032-873-9290"
            fax="032-876-9290" />
        <contact-slide>
            <v-img src="/images/sub/contact/incheon/incheon-img.jpg" max-width="952" :aspect-ratio="952 / 634" class="w-100" />
            <!-- <swiper-slide v-for="detail in slide" :key="detail" class="swiper swiper--slide">
                <div class="slide-contents__right__inner">
                    <div class="slide-contents__right__image-wrap">
                        <div class="slide-contents__right__image">
                            <div class="slide-contents__right__image__inner image-card" :style="'background-image: url('+detail+')'"></div>
                        </div>
                    </div> 
                </div>
            </swiper-slide> -->
        </contact-slide>
    </client-page>
</template>

<script>
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";
import ContactInfo from "@/sets/styles/contact-info.vue";
import ContactSlide from "@/sets/styles/contact-slide.vue";

import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    props: {},
    components: {
        ClientPage,
        SubVisual,
        PageSection,
        ContactInfo,
        ContactSlide,

        Tit,
        Txt,
    },
    data: () => {
        return {            
            // slide : [
            //     "/images/sub/contact/incheon/incheon-img.jpg",
            //     "/images/sub/contact/seoul/seoul-img.jpg",
            // ]
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
</style>
