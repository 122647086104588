<template>
    <section v-if="!this.$slots['gridSectionRight']" class="page-section">
        <slot v-if="this.$slots['pageSectionImmersive']" name="pageSectionImmersive" />
        <v-container>
            <tit-wrap-primary v-if="tit" :dark="dark" class="text-center" :class="titClass">
                {{tit}}
                <template #TitAdd>{{titAdd}}</template>
            </tit-wrap-primary>
            <slot />
        </v-container>
    </section>
    <section v-else class="grid-section" :style="'background-image: url('+gridSectionBg+')'">
        <v-container>
            <v-row no-gutters>
                <v-col cols="12" md="6">
                    <div class="page-section grid-section__contents"
                    :class="{ 'grey lighten-5': !dark && !$vuetify.breakpoint.mdAndUp, 'secondary darken-4': dark && !$vuetify.breakpoint.mdAndUp }">
                        <v-img v-if="!$vuetify.breakpoint.mdAndUp" :src="dark ? '/images/sub/left-bg-dark.svg' : '/images/sub/left-bg-light.svg'"
                        contain :aspect-ratio="1840 / 480" class="grid-section__left-bg w-100"/>
                        <tit-wrap-primary v-if="tit" :dark="dark" class="pb-0">
                            {{tit}}
                            <template #TitAdd>{{titAdd}}</template>
                        </tit-wrap-primary>
                        <slot v-if="this.$slots['gridSectionLeft']" name="gridSectionLeft" />
                    </div>
                </v-col>
                <v-col data-aos="fade-up" data-aos-delay="200" cols="12" md="6">
                    <div class="page-section grid-section__contents px-md-30px pl-lg-80px pr-lg-20px pr-xl-0" :style="'background-image: url('+gridSectionRightBg+')'">
                        <slot v-if="this.$slots['gridSectionRight']" name="gridSectionRight" />
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<script>
import TitWrapPrimary from "@/components/publish/parents/typography/tit-wrap-primary.vue";

export default {
    props: {
        gridSectionBg: { type: String, default: "" },
        gridSectionRightBg: { type: String, default: "" },
        tit: { type: String, default: "" },
        titAdd: { type: String, default: "" },
        titClass: { type: String, default: "" },
        dark: { type: Boolean, default: false },
    },
    components: {
        TitWrapPrimary,
    },
    data: () => {
        return {};
    },
    created() {},
    mounted() {
        this.init();
    },
    methods: {
        init: function () {},
    },
};
</script>

<style lang="scss" scoped>
.page-section {
    width: 100%;
    position: relative;
    padding: var(--page-section-padding-y) 0;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .container {
        position: relative;
        z-index: 1;
    }
    &--sm {
        padding: var(--page-section-padding-y-sm) 0;
    }
    &--first {
        padding-top: var(--contents-padding-top);
        // margin-top: calc(var(--contents-padding-top) * -1);
    }
    &--last {
        padding-bottom: var(--contents-padding-bottom);
        // margin-bottom: calc(var(--contents-padding-bottom) * -1);
    }
    &__bg {
        position: absolute;
        top: 0;
        left: 50%;
        -webkit-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
        z-index: -1;
        display: block;
        width: 100vw;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
}
.grid-section{
    ::v-deep{
        >.container{
            padding: 0;
        }
    }
    &,
    &__contents{
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }
    &__contents{
        padding-left: var(--container-gutter);
        padding-right: var(--container-gutter);
        height: 100%;
    }
    &__left-bg{
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 0;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .grid-section{
        ::v-deep{
            >.container{
                padding: 0 var(--container-gutter);
            }
        }
        &__contents{
            padding-left: 0;
            padding-right: 0;
            background-image: none !important;
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
@media (max-width: 767px) {
    .grid-section{
        background-image: none !important;
    }
}
</style>
