var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "오시는 길",
            "tabActive": "서울이혼가사센터",
            "bg": "/images/sub/visual/sv-contact.jpg"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('contact-info', {
    attrs: {
      "title": "서울이혼가사센터",
      "iframe": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3166.104409759947!2d127.03461277635908!3d37.481862428978204!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca16c0c06ec01%3A0x43cc526dfc30b3a3!2z7J207Zi87KCE66y467OA7Zi47IKsIOuyleustOuyleyduCDtg5zshLE!5e0!3m2!1sko!2skr!4v1691649430271!5m2!1sko!2skr",
      "address": "서울 서초구 강남대로 196 구민빌딩 2층",
      "addressLink": "https://goo.gl/maps/VUhH2iNyGfukpJP39",
      "tel": "02-6952-9291",
      "fax": "02-3472-3162"
    }
  }), _c('contact-slide', [_c('v-img', {
    staticClass: "w-100",
    attrs: {
      "src": "/images/sub/contact/seoul/seoul-img.jpg",
      "max-width": "952",
      "aspect-ratio": 952 / 634
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }