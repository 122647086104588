<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="이혼" tabActive="재산분할">
                <template #video>
                    <video src="/images/sub/visual/sv-divorce.mp4" poster="/images/sub/visual/sv-divorce.jpg" loop autoplay muted playsinline />
                </template>
            </sub-visual>
        </template>
        <page-section dark tit="재산분할" titAdd="Divorce" style="background-image: url(/images/sub/divorce/property/property-bg.jpg)">
            <card-primary>
                <strong class="primary--text">“한 푼도 못줘!” 라는 말을 들으셨나요?</strong> <br />
                그러면 앞으로도 합의는 어렵습니다. 재산분할 청구를 통해 자신의 권리를 찾으세요.
            </card-primary>
        </page-section>
        <page-section tit="재산분할 청구" titAdd="Divorce" class="page-section-divorce">
            <div class="card-rows">
                <card-row
                image="/images/sub/divorce/property/property-img.jpg"
                txt="이혼 시 또는 이혼 후에는 혼인기간 동안 부부가 협력하여 이룬 재산에 대하여 분할을 청구할 수 있습니다. 재산분할청구는 이혼소송 시 함께 병합하여 진행하는 경우가 대부분이나, 협의이혼을 하고 재산에 대해서는 의사의 합치가 이루어지지 않은 경우에는 협의이혼 후 2년 내에 재산분할청구가 가능하기 때문에 별도로 진행하실 수 있습니다." />
            </div>
        </page-section>
        <page-section dark tit="재산분할의 기준" titAdd="Divorce" gridSectionBg="/images/sub/divorce/property/property-bg2.jpg" gridSectionRightBg="/images/sub/divorce/property/property-bg2-mo.jpg">
            <template #gridSectionRight>                
                <div class="cards">
                    <card-secondary v-for="detail in standard" :key="detail">{{detail}}</card-secondary>
                </div>
                <txt class="white--text line-height-17 mt-12px mt-md-24px">
                    수입이 전혀 없었던 배우자 일방의 경우에도 재산 감소, 유지를 위해 노력했다면 상당한 기여도를 인정받을 수 있습니다.
                </txt>
            </template>
        </page-section>
        <page-section style="background-image: url(/images/sub/divorce/property/property-bg3.jpg)">
            <tit-wrap-secondary>
                재산은닉을 방지하기 위한 부동산 가압류
                <template #TitAdd>
                    Divorce
                </template>
                <template #TxtAdd>
                    <p>
                        부부가 협력하여 이룬 재산이 상대방의 명의로 되어있는 경우 이혼소송을 준비하는 과정에서 상대방 재산을 처분, 은닉할 가능성이 있습니다. <br class="d-none d-xl-block">
                        이를 방지하기 위하여 이혼소송을 하기 전 신속히 부동산 가압류를 진행하셔야 합니다.
                    </p>
                </template>
            </tit-wrap-secondary>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";
import CardRow from "@/sets/styles/card-row.vue";
import CardPrimary from "@/components/publish/parents/cards/card-primary.vue";
import CardSecondary from "@/components/publish/parents/cards/card-secondary.vue";
import TitWrapSecondary from "@/components/publish/parents/typography/tit-wrap-secondary.vue";

import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    props: {},
    components: {
        ClientPage,
        SubVisual,
        PageSection,
        CardRow,
        CardPrimary,
        CardSecondary,
        TitWrapSecondary,

        Tit,
        Txt,
    },
    data() {
        return {                                   
            standard : [
                "혼인기간",
                "양 당사자의 수입",
                "혼인 전 재산의 유무",
                "재산 감소를 위한 기여도",
            ],
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.page-section-divorce{
    background-image: url(/images/sub/divorce/divorce-bg.svg);
    background-size: 100% auto;
    background-position: center bottom
}
</style>