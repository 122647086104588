var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page-section', {
    staticClass: "page-section--slide grey lighten-5"
  }, [_c('tit-wrap-primary', {
    staticClass: "page-section--slide__title",
    scopedSlots: _vm._u([{
      key: "TitAdd",
      fn: function () {
        return [_vm._v("Contact Us")];
      },
      proxy: true
    }])
  }, [_vm._v(" 사무실 전경 ")]), _c('div', {
    staticClass: "slide-contents"
  }, [_c('div', {
    staticClass: "slide-contents__slide w-100"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "slide-contents__right",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200",
      "cols": "12",
      "md": "8",
      "offset-md": "4"
    }
  }, [_vm._t("default")], 2), _c('v-col', {
    staticClass: "slide-contents__left",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "300",
      "cols": "12",
      "md": "10",
      "lg": "5"
    }
  }, [_c('div', {
    staticClass: "slide-contents__left__inner"
  }, [_c('v-card', {
    staticClass: "slide-contents__left__card",
    attrs: {
      "rounded": "",
      "dark": "",
      "color": "primary lighten-1"
    }
  }, [_c('div', {
    staticClass: "pa-20px pa-md-30px pa-lg-60px"
  }, [_c('tit', {
    staticClass: "white--text"
  }, [_vm._v(" 법무법인 태성의 변호사는 "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 여러분 곁에 있습니다. ")])], 1)])], 1)])], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }