var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "이혼",
            "tabActive": "재산분할"
          },
          scopedSlots: _vm._u([{
            key: "video",
            fn: function () {
              return [_c('video', {
                attrs: {
                  "src": "/images/sub/visual/sv-divorce.mp4",
                  "poster": "/images/sub/visual/sv-divorce.jpg",
                  "loop": "",
                  "autoplay": "",
                  "muted": "",
                  "playsinline": ""
                },
                domProps: {
                  "muted": true
                }
              })];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section', {
    staticStyle: {
      "background-image": "url(/images/sub/divorce/property/property-bg.jpg)"
    },
    attrs: {
      "dark": "",
      "tit": "재산분할",
      "titAdd": "Divorce"
    }
  }, [_c('card-primary', [_c('strong', {
    staticClass: "primary--text"
  }, [_vm._v("“한 푼도 못줘!” 라는 말을 들으셨나요?")]), _vm._v(" "), _c('br'), _vm._v(" 그러면 앞으로도 합의는 어렵습니다. 재산분할 청구를 통해 자신의 권리를 찾으세요. ")])], 1), _c('page-section', {
    staticClass: "page-section-divorce",
    attrs: {
      "tit": "재산분할 청구",
      "titAdd": "Divorce"
    }
  }, [_c('div', {
    staticClass: "card-rows"
  }, [_c('card-row', {
    attrs: {
      "image": "/images/sub/divorce/property/property-img.jpg",
      "txt": "이혼 시 또는 이혼 후에는 혼인기간 동안 부부가 협력하여 이룬 재산에 대하여 분할을 청구할 수 있습니다. 재산분할청구는 이혼소송 시 함께 병합하여 진행하는 경우가 대부분이나, 협의이혼을 하고 재산에 대해서는 의사의 합치가 이루어지지 않은 경우에는 협의이혼 후 2년 내에 재산분할청구가 가능하기 때문에 별도로 진행하실 수 있습니다."
    }
  })], 1)]), _c('page-section', {
    attrs: {
      "dark": "",
      "tit": "재산분할의 기준",
      "titAdd": "Divorce",
      "gridSectionBg": "/images/sub/divorce/property/property-bg2.jpg",
      "gridSectionRightBg": "/images/sub/divorce/property/property-bg2-mo.jpg"
    },
    scopedSlots: _vm._u([{
      key: "gridSectionRight",
      fn: function () {
        return [_c('div', {
          staticClass: "cards"
        }, _vm._l(_vm.standard, function (detail) {
          return _c('card-secondary', {
            key: detail
          }, [_vm._v(_vm._s(detail))]);
        }), 1), _c('txt', {
          staticClass: "white--text line-height-17 mt-12px mt-md-24px"
        }, [_vm._v(" 수입이 전혀 없었던 배우자 일방의 경우에도 재산 감소, 유지를 위해 노력했다면 상당한 기여도를 인정받을 수 있습니다. ")])];
      },
      proxy: true
    }])
  }), _c('page-section', {
    staticStyle: {
      "background-image": "url(/images/sub/divorce/property/property-bg3.jpg)"
    }
  }, [_c('tit-wrap-secondary', {
    scopedSlots: _vm._u([{
      key: "TitAdd",
      fn: function () {
        return [_vm._v(" Divorce ")];
      },
      proxy: true
    }, {
      key: "TxtAdd",
      fn: function () {
        return [_c('p', [_vm._v(" 부부가 협력하여 이룬 재산이 상대방의 명의로 되어있는 경우 이혼소송을 준비하는 과정에서 상대방 재산을 처분, 은닉할 가능성이 있습니다. "), _c('br', {
          staticClass: "d-none d-xl-block"
        }), _vm._v(" 이를 방지하기 위하여 이혼소송을 하기 전 신속히 부동산 가압류를 진행하셔야 합니다. ")])];
      },
      proxy: true
    }])
  }, [_vm._v(" 재산은닉을 방지하기 위한 부동산 가압류 ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }