var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !this.$slots['gridSectionRight'] ? _c('section', {
    staticClass: "page-section"
  }, [this.$slots['pageSectionImmersive'] ? _vm._t("pageSectionImmersive") : _vm._e(), _c('v-container', [_vm.tit ? _c('tit-wrap-primary', {
    staticClass: "text-center",
    class: _vm.titClass,
    attrs: {
      "dark": _vm.dark
    },
    scopedSlots: _vm._u([{
      key: "TitAdd",
      fn: function () {
        return [_vm._v(_vm._s(_vm.titAdd))];
      },
      proxy: true
    }], null, false, 3615817904)
  }, [_vm._v(" " + _vm._s(_vm.tit) + " ")]) : _vm._e(), _vm._t("default")], 2)], 2) : _c('section', {
    staticClass: "grid-section",
    style: 'background-image: url(' + _vm.gridSectionBg + ')'
  }, [_c('v-container', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "page-section grid-section__contents",
    class: {
      'grey lighten-5': !_vm.dark && !_vm.$vuetify.breakpoint.mdAndUp,
      'secondary darken-4': _vm.dark && !_vm.$vuetify.breakpoint.mdAndUp
    }
  }, [!_vm.$vuetify.breakpoint.mdAndUp ? _c('v-img', {
    staticClass: "grid-section__left-bg w-100",
    attrs: {
      "src": _vm.dark ? '/images/sub/left-bg-dark.svg' : '/images/sub/left-bg-light.svg',
      "contain": "",
      "aspect-ratio": 1840 / 480
    }
  }) : _vm._e(), _vm.tit ? _c('tit-wrap-primary', {
    staticClass: "pb-0",
    attrs: {
      "dark": _vm.dark
    },
    scopedSlots: _vm._u([{
      key: "TitAdd",
      fn: function () {
        return [_vm._v(_vm._s(_vm.titAdd))];
      },
      proxy: true
    }], null, false, 3615817904)
  }, [_vm._v(" " + _vm._s(_vm.tit) + " ")]) : _vm._e(), this.$slots['gridSectionLeft'] ? _vm._t("gridSectionLeft") : _vm._e()], 2)]), _c('v-col', {
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200",
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "page-section grid-section__contents px-md-30px pl-lg-80px pr-lg-20px pr-xl-0",
    style: 'background-image: url(' + _vm.gridSectionRightBg + ')'
  }, [this.$slots['gridSectionRight'] ? _vm._t("gridSectionRight") : _vm._e()], 2)])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }