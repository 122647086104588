<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="상간자소송" bg="/images/sub/visual/sv-lawsuit.jpg" />
        </template>
        <page-section dark tit="상간자소송" titAdd="Lawsuit" style="background-image: url(/images/sub/lawsuit/lawsuit-bg2.jpg)">
            <card-primary>
                <strong class="primary--text">외도를 저지른 사람이 오히려 나에게 원인을 제공했다고 말하나요?</strong> <br />
                혼인파탄의 책임을 스스로 느끼지 못한다면 법으로 알게 해 주세요.
            </card-primary>
        </page-section>
        <page-section tit="위자료청구" titAdd="Lawsuit" class="page-section-lawsuit">
            <div class="card-rows">
                <card-row
                image="/images/sub/lawsuit/lawsuit-img.jpg"
                txt="혼인이 파탄에 이르게 된 원인이 상대방에게 있다면 이로 인한 정신적 고통에 대한 배상 즉, 위자료를 청구할 수 있습니다. 위자료청구는 이혼소송과 병합하여 진행되는 것이 일반적이지만 협의이혼 후 위자료청구만 별도로 진행하는 것도 가능합니다. 이 경우에는 협의이혼 후 3년 내에 청구할 수 있습니다." />
            </div>
        </page-section>
        <page-section dark tit="위자료액수 산정기준" titAdd="Lawsuit" gridSectionBg="/images/sub/lawsuit/lawsuit-bg3.jpg" gridSectionRightBg="/images/sub/lawsuit/lawsuit-bg3-mo.jpg">
            <template #gridSectionRight>                
                <div class="cards">
                    <card-secondary v-for="detail in standard" :key="detail">{{detail}}</card-secondary>
                </div>
            </template>
        </page-section>
        <page-section style="background-image: url(/images/sub/lawsuit/lawsuit-bg4.jpg)">
            <tit-wrap-secondary>
                내연남&내연녀를 상대로 한 위자료청구
                <template #TitAdd>
                    Lawsuit
                </template>
                <template #TxtAdd>
                    <p>
                        상대방이 부정행위를 저지른 경우 배우자와 그 상대에게 위자료를 청구하는 것도 가능하며, 배우자를 제외하고 내연남&내연녀를 상대로 하여 <br class="d-none d-xl-block">
                        위자료 청구가 가능합니다.
                    </p>
                </template>
            </tit-wrap-secondary>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";
import CardRow from "@/sets/styles/card-row.vue";
import CardPrimary from "@/components/publish/parents/cards/card-primary.vue";
import CardSecondary from "@/components/publish/parents/cards/card-secondary.vue";
import TitWrapSecondary from "@/components/publish/parents/typography/tit-wrap-secondary.vue";

import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    props: {},
    components: {
        ClientPage,
        SubVisual,
        PageSection,
        CardRow,
        CardPrimary,
        CardSecondary,
        TitWrapSecondary,

        Tit,
        Txt,
    },
    data() {
        return {                                   
            standard : [
                "혼인 파탄의 원인과 책임",
                "혼인기간",
                "유책배우자의 재산상태",
            ],

        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.page-section-lawsuit{
    background-image: url(/images/sub/lawsuit/lawsuit-bg.svg);
    background-size: 100% auto;
    background-position: center bottom
}
</style>
