<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="상속" tabActive="유류분" bg="/images/sub/visual/sv-inheritance.jpg" />
        </template>
        <page-section dark tit="유류분청구" titAdd="Inheritance" style="background-image: url(/images/sub/inheritance/heir/heir-bg.jpg)">
            <card-primary>
                유류분이란 사망자(피상속인)이 사망 전에 공동상속인 또는 제3자에게 자신의 재산을 처분한 경우, 증여 또는 유증을 <br class="d-none d-xl-block">
                전혀 받지 못한 상속인들의 최소한의 권리보장을 위한 제도입니다. 즉, 상속인이 상속분 중 일정비율을 피상속인으로부터 <br class="d-none d-xl-block">
                증여 또는 유증받은 자에게 청구할 수 있습니다.
            </card-primary>
        </page-section>
        <page-section tit="유류분청구의 절차" titAdd="Inheritance" class="page-section-inheritance">
            <div class="card-rows">
                <card-row v-for="item in process" :key="item.txt" :image="item.image" :txt="item.txt" :caption="item.caption" />
            </div>
        </page-section>
        <page-section style="background-image: url(/images/sub/inheritance/heir/heir-bg2.jpg)">
            <tit-wrap-secondary dark>
                유류분청구에서 주의할 점
                <template #TitAdd>
                    Inheritance
                </template>
                <template #TxtAdd>
                    유류분은 피상속인의 직계비속, 배우자의 경우 법정상속분의 1/2이며, 직계존속, 형제자매의 경우 1/3입니다. 그러나 이 계산에 있어서 소멸시효, <br class="d-none d-xl-block">
                    상속재산의 시가 기준시, 상속채무 공제, 미리 증여받은 재산의 유무 등의 요소들이 반영될 수 있어 이에 대한 전문적인 상담이 필요합니다.
                </template>
            </tit-wrap-secondary>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";
import CardRow from "@/sets/styles/card-row.vue";
import CardPrimary from "@/components/publish/parents/cards/card-primary.vue";
import TitWrapSecondary from "@/components/publish/parents/typography/tit-wrap-secondary.vue";

import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    props: {},
    components: {
        ClientPage,
        SubVisual,
        PageSection,
        CardRow,
        CardPrimary,
        TitWrapSecondary,

        Tit,
        Txt,
    },
    data() {
        return {            
            process : [
                {
                    txt : "유류분은 상속인 중 1인 또는 수인이 피상속인으로부터 증여, 유증을 받은 상속인 또는 제3자 등를 상대로 청구",
                    caption : "* 관할 법원은 상대방의 주소지 또는 부동산의 소재지 민사법원",
                    image : "/images/sub/inheritance/heir/heir-img.jpg",
                },
                {
                    txt : "법원에서는 상속인의 유류분을 산정하여 유류분을 침해하는 한도에서 유류분반환의 판결",
                    image : "/images/sub/inheritance/heir/heir-img2.jpg",
                },
            ],
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.page-section-inheritance{
    background-image: url(/images/sub/inheritance/inheritance-bg.svg);
    background-size: 100% auto;
    background-position: center bottom
}
</style>