var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "상속",
            "tabActive": "상속 재산 분할",
            "bg": "/images/sub/visual/sv-inheritance.jpg"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('page-section', {
    staticStyle: {
      "background-image": "url(/images/sub/inheritance/property/property-bg.jpg)"
    },
    attrs: {
      "dark": "",
      "tit": "상속재산분할청구",
      "titAdd": "Inheritance"
    }
  }, [_c('card-primary', [_vm._v(" 사망자(피상속인)의 재산은 공동상속인들의 공유가 됩니다. 공동상속인들은 협의를 통해 상속재산을 분할해야 하나, "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 협의가 원만하지 않은 경우 가정법원에 분할청구가 가능합니다. 주로 공동상속인들 중에 미리 상속인으로부터 증여받은 것이 있거나, "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 부모님을 모셨다는 등의 기여가 있다면 협의가 원만하지 않기 때문에 법원에서는 이를 고려하여 피상속인의 재산을 분할합니다. ")])], 1), _c('page-section', {
    staticClass: "page-section-inheritance",
    attrs: {
      "tit": "분할의 절차",
      "titAdd": "Inheritance"
    }
  }, [_c('div', {
    staticClass: "card-rows"
  }, _vm._l(_vm.process, function (item) {
    return _c('card-row', {
      key: item.txt,
      attrs: {
        "image": item.image,
        "txt": item.txt,
        "caption": item.caption
      }
    });
  }), 1)]), _c('page-section', {
    staticStyle: {
      "background-image": "url(/images/sub/inheritance/property/property-bg2.jpg)"
    }
  }, [_c('tit-wrap-secondary', {
    attrs: {
      "dark": ""
    },
    scopedSlots: _vm._u([{
      key: "TitAdd",
      fn: function () {
        return [_vm._v(" Inheritance ")];
      },
      proxy: true
    }, {
      key: "TxtAdd",
      fn: function () {
        return [_vm._v(" 상속재산분할 과정에서 자신이 망인 즉 피상속인에게 기여한 바가 있거나, 그 외에 다른 사정이 있는 경우 이를 구체적으로 주장, 입증하여야 합니다. "), _c('br', {
          staticClass: "d-none d-xl-block"
        }), _vm._v(" 법원에서 알아서 합리적으로 분할해줄 것을 기여하고 감정에만 호소하여 주장할 경우에는 합리적인 결과를 얻기 어렵습니다. ")];
      },
      proxy: true
    }])
  }, [_vm._v(" 상속재산분할청구에서 주의할 점 ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }