var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sub-head"
  }, [_c('div', {
    staticClass: "sub-visual grey darken-4"
  }, [_c('div', {
    staticClass: "sub-visual__bg"
  }, [this.$slots['video'] ? _c('div', {
    staticClass: "sub-visual__bg__inner sub-visual__bg__inner--video",
    attrs: {
      "data-aos": "zoom-out",
      "data-aos-duration": "1000"
    }
  }, [_vm._t("video")], 2) : _c('div', {
    staticClass: "sub-visual__bg__inner",
    style: 'background-image:url(' + _vm.bg + ');',
    attrs: {
      "data-aos": "zoom-out",
      "data-aos-duration": "1000"
    }
  })]), _c('v-container', [_c('div', {
    staticClass: "sub-visual__tit-wrap",
    class: {
      'mb-0': !_vm.tabActive
    }
  }, [_c('tit', {
    staticClass: "sub-visual__tit tit--xxl white--text overflow-hidden"
  }, [_c('span', {
    staticClass: "d-block",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "600"
    }
  }, [_vm._v(_vm._s(_vm.sh))])]), _c('div', {
    staticClass: "icon-rounded-object-wrap",
    attrs: {
      "data-aos": "zoom-out",
      "data-aos-delay": "1100"
    }
  }, [_c('icon-rounded-object', {
    attrs: {
      "color": "white--text"
    }
  })], 1)], 1), _vm.tabActive ? _c('div', {
    staticClass: "overflow-hidden"
  }, [_vm.$vuetify.breakpoint.mdAndUp ? _c('client-gnb', {
    attrs: {
      "className": "sub-tab",
      "data-aos": "fade-up",
      "data-aos-delay": "700",
      "tabActive": _vm.tabActive,
      "sh": _vm.sh
    }
  }) : _vm._e()], 1) : _vm._e()])], 1), !_vm.$vuetify.breakpoint.mdAndUp && _vm.tabActive ? _c('client-gnb', {
    attrs: {
      "className": "sub-tab",
      "tabActive": _vm.tabActive,
      "sh": _vm.sh
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }