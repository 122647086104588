var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "상속",
            "tabActive": "유류분",
            "bg": "/images/sub/visual/sv-inheritance.jpg"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('page-section', {
    staticStyle: {
      "background-image": "url(/images/sub/inheritance/heir/heir-bg.jpg)"
    },
    attrs: {
      "dark": "",
      "tit": "유류분청구",
      "titAdd": "Inheritance"
    }
  }, [_c('card-primary', [_vm._v(" 유류분이란 사망자(피상속인)이 사망 전에 공동상속인 또는 제3자에게 자신의 재산을 처분한 경우, 증여 또는 유증을 "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 전혀 받지 못한 상속인들의 최소한의 권리보장을 위한 제도입니다. 즉, 상속인이 상속분 중 일정비율을 피상속인으로부터 "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 증여 또는 유증받은 자에게 청구할 수 있습니다. ")])], 1), _c('page-section', {
    staticClass: "page-section-inheritance",
    attrs: {
      "tit": "유류분청구의 절차",
      "titAdd": "Inheritance"
    }
  }, [_c('div', {
    staticClass: "card-rows"
  }, _vm._l(_vm.process, function (item) {
    return _c('card-row', {
      key: item.txt,
      attrs: {
        "image": item.image,
        "txt": item.txt,
        "caption": item.caption
      }
    });
  }), 1)]), _c('page-section', {
    staticStyle: {
      "background-image": "url(/images/sub/inheritance/heir/heir-bg2.jpg)"
    }
  }, [_c('tit-wrap-secondary', {
    attrs: {
      "dark": ""
    },
    scopedSlots: _vm._u([{
      key: "TitAdd",
      fn: function () {
        return [_vm._v(" Inheritance ")];
      },
      proxy: true
    }, {
      key: "TxtAdd",
      fn: function () {
        return [_vm._v(" 유류분은 피상속인의 직계비속, 배우자의 경우 법정상속분의 1/2이며, 직계존속, 형제자매의 경우 1/3입니다. 그러나 이 계산에 있어서 소멸시효, "), _c('br', {
          staticClass: "d-none d-xl-block"
        }), _vm._v(" 상속재산의 시가 기준시, 상속채무 공제, 미리 증여받은 재산의 유무 등의 요소들이 반영될 수 있어 이에 대한 전문적인 상담이 필요합니다. ")];
      },
      proxy: true
    }])
  }, [_vm._v(" 유류분청구에서 주의할 점 ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }