<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="태성 이혼가사센터" tabActive="최유나 변호사 소개" bg="/images/sub/visual/sv-about.jpg" />
        </template>
        <page-section tit="최유나 변호사 소개" titAdd="법무법인 태성" class="lawyer-view__head" titClass="pb-0">
            <template #pageSectionImmersive>
                <v-img src="/images/sub/taesung/lawyer/lawyer-bg.svg" max-width="1840" :aspect-ratio="1840 / 266" class="text-bg w-100" />
            </template>
        </page-section>
        <page-section class="lawyer-view__body secondary">
            <v-row>
                <v-col cols="12" sm="7">
                    <tit data-aos="fade-up" class="tit--lg white--text">
                        따스한 봄을 맞이하도록 도와드리겠습니다. <br>
                        <span class="primary--text">봄은 다시 옵니다.</span>
                    </tit>
                    <span data-aos="fade-up" data-aos-delay="100" class="line my-12px my-md-24px"></span>
                    <txt data-aos="fade-up" data-aos-delay="200" class="white--text line-height-17">
                        <p class="mb-12px mb-md-24px">이혼전문변호사 최유나입니다. 홈페이지를 찾아주셔서 감사합니다.</p>
                        <p>
                            숱한 고민을 거쳐 어렵게 내리신 결정인 만큼, 의뢰인과의 충분한 공감과 소통을 통하여 최고의 법률서비스를
                            제공해 드리겠습니다. 소송 전 과정에서 의뢰인분의 한마디 한마디에 귀 기울이고, 경청하여 최상의 결과를
                            안겨드리고 새로운 삶으로 나아갈 수 있는 방향을 설정해 드리겠습니다. 
                        </p>
                    </txt>
                </v-col>
                <v-col cols="8" sm="5" offset="4" offset-sm="7" offset-md="0">
                    <div class="lawyer-img-wrap">
                        <v-img data-aos="fade-left" src="/images/lawyer/view/최유나.png" max-width="586" :aspect-ratio="586 / 900" class="lawyer-img w-100"/>
                    </div>
                </v-col>
            </v-row>
        </page-section>
        <page-section class="lawyer-view__foot page-section--last page-section--sm">
            <info-row v-for="(item, index) in info" :key="index" :title="item.title" :topLine="index == 0">
                <info-detail v-for="(detail, index) in item.infoDetail" :key="index" :class="index !== 0 ? 'mt-10px mt-md-16px' : ''">
                    {{detail}}
                </info-detail>
            </info-row>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";
import InfoRow from "@/sets/styles/info-row.vue";
import InfoDetail from "@/sets/styles/info-detail.vue";

import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    props: {},
    components: {
        ClientPage,
        SubVisual,
        PageSection,
        InfoRow,
        InfoDetail,

        Tit,
        Txt,
    },
    data() {
        return {            
            info: [
                {
                    title : "소개",
                    infoDetail : [
                        "대한변호사협회 등록 이혼, 가사법 전문변호사",
                    ],
                },
                {
                    title : "경력",
                    infoDetail : [
                        "법무법인 태성 대표변호사",
                        "대한변호사협회 이혼전문변호사",
                        "대한변호사협회 가사법전문변호사",
                        "상속유류분소송 담당 변호사",
                        "이혼, 사실혼 위자료, 재산분할, 양육권 등 수백여건 소송 진행",
                        "내연남, 내연녀 위자료청구",
                        "가정폭력, 접근금지가처분",
                        "혼인무효, 취소 소송",
                        "유류분, 기여분 및 상속재산분쟁",
                        "친자관계소송 (친생부인, 친자관계존부확인, 인지청구 등)",
                        "국제이혼, 다문화 가정 이혼",
                        "전) 매일경제칼럼리스트",
                        "전) 네이버법률판변호사",
                    ],
                },
                {
                    title : "그 외 활동 및 방송출연",
                    infoDetail : [
                        "MBC생방송 오늘아침 출연",
                        "5만명 구독 이혼툰 <메리지레드> 연재",
                        "베스트셀러 <혼자와함께사이>, <우리이만헤어져요>",
                        "TVN 유퀴즈 제헌절특집 출연",
                        "SBS 돌싱포맨 KBS 아침마당 등 다수방송출연",
                        "네이버연애결혼,법률판 (전)자문",
                        "매일경제(전)칼럼리스트",
                        "세바시강연 200만뷰",
                        "CBS시사자키 출연",
                        "주간동아, 여성동아, 한국일보, 조선일보 출연",
                        "'워크맨' 외 다수 유튜브 출연",
                    ],
                },
            ]
        }
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.lawyer-view__foot{
    background-image: url(/images/sub/taesung/chio/chio-bg.svg);
}
</style>
