var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "이혼",
            "tabActive": "양육권&친권"
          },
          scopedSlots: _vm._u([{
            key: "video",
            fn: function () {
              return [_c('video', {
                attrs: {
                  "src": "/images/sub/visual/sv-divorce.mp4",
                  "poster": "/images/sub/visual/sv-divorce.jpg",
                  "loop": "",
                  "autoplay": "",
                  "muted": "",
                  "playsinline": ""
                },
                domProps: {
                  "muted": true
                }
              })];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section', {
    staticStyle: {
      "background-image": "url(/images/sub/divorce/custody/custody-bg.jpg)"
    },
    attrs: {
      "dark": "",
      "tit": "양육권&친권",
      "titAdd": "Divorce"
    }
  }, [_c('card-primary', [_c('strong', {
    staticClass: "primary--text"
  }, [_vm._v("양육권 다툼은 시작 전 준비가 관건입니다.")]), _vm._v(" "), _c('br'), _vm._v(" 본격적인 다툼을 시작하기 전에 어떻게 하면 우리 아이의 일상을 내가 지켜 줄 수 있을지 "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 미리 상담 받으세요. 싸움이 벌어지면 늦습니다. ")])], 1), _c('page-section', {
    staticClass: "page-section-divorce",
    attrs: {
      "tit": "양육권 및 친권은 어떻게 정해지나?",
      "titAdd": "Divorce"
    }
  }, [_c('div', {
    staticClass: "card-rows"
  }, [_c('card-row', {
    attrs: {
      "image": "/images/sub/divorce/custody/custody-img.jpg",
      "txt": "재산은 분할할 수 있지만, 자녀의 양육은 분할이 어렵기 때문에 부부 간에 양육권 다툼이 있는 경우에는 그 어떤 소송보다 치열하게 진행됩니다. 친권도 주로 양육권자에게 함께 지정되고 비양육권자는 양육비지급의무가 있기 때문에 이혼소송에 있어 양육권자 지정은 큰 의미를 가집니다."
    }
  })], 1)]), _c('page-section', {
    attrs: {
      "dark": "",
      "tit": "양육권 지정 기준",
      "titAdd": "Divorce",
      "gridSectionBg": "/images/sub/divorce/custody/custody-bg2.jpg",
      "gridSectionRightBg": "/images/sub/divorce/custody/custody-bg2-mo.jpg"
    },
    scopedSlots: _vm._u([{
      key: "gridSectionRight",
      fn: function () {
        return [_c('div', {
          staticClass: "cards"
        }, _vm._l(_vm.standard, function (detail) {
          return _c('card-secondary', {
            key: detail
          }, [_vm._v(_vm._s(detail))]);
        }), 1)];
      },
      proxy: true
    }])
  }), _c('page-section', {
    staticStyle: {
      "background-image": "url(/images/sub/divorce/custody/custody-bg3.jpg)"
    }
  }, [_c('tit-wrap-secondary', {
    scopedSlots: _vm._u([{
      key: "TitAdd",
      fn: function () {
        return [_vm._v(" Divorce ")];
      },
      proxy: true
    }, {
      key: "TxtAdd",
      fn: function () {
        return [_c('p', [_vm._v(" 이러한 사항들을 면밀히 조사하기 위해 이혼소송 중 양육권 다툼이 있는 경우, 재판부에서 가사조사명령을 내리게 됩니다. 이 명령에 따라 "), _c('br', {
          staticClass: "d-none d-xl-block"
        }), _vm._v(" 가사조사관들은 부부와의 면담을 통해서 양육권판단에 기준이 되는 요소들을 조사하게 됩니다. ")]), _c('p', {
          staticClass: "mt-12px mt-md-24px"
        }, [_vm._v(" 양육권소송에 있어서의 핵심은 위 가사조사절차에서 당황하지 않고, 양육권 지정에 영향을 미치는 기준들을 잘 주장하고 소명해 내는 것입니다. "), _c('br', {
          staticClass: "d-none d-xl-block"
        }), _vm._v(" 감정에만 호소하여 양육권 소송에서 패하는 경우가 굉장히 많기 때문에 양육권소송에서는 전문가의 도움이 꼭 필요합니다. ")])];
      },
      proxy: true
    }])
  }, [_vm._v(" 가사조사절차에 대하여 ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }