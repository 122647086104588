<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="태성 이혼가사센터" tabActive="문종하 변호사 소개" bg="/images/sub/visual/sv-about.jpg" />
        </template>
        <page-section tit="문종하 변호사 소개" titAdd="법무법인 태성" class="lawyer-view__head" titClass="pb-0">
            <template #pageSectionImmersive>
                <v-img src="/images/sub/taesung/lawyer/lawyer-bg.svg" max-width="1840" :aspect-ratio="1840 / 266" class="text-bg w-100" />
            </template>
        </page-section>
        <page-section class="lawyer-view__body secondary">
            <v-row>
                <v-col cols="12" sm="7">
                    <tit data-aos="fade-up" class="tit--lg white--text">
                        <span class="primary--text">당신의 새로운 시작에 함께 하겠습니다. </span>
                    </tit>
                    <span data-aos="fade-up" data-aos-delay="100" class="line my-12px my-md-24px"></span>
                    <txt data-aos="fade-up" data-aos-delay="200" class="white--text line-height-17">
                        <p class="mb-12px mb-md-24px">안녕하세요. 이혼전문변호사 문종하입니다.</p>
                        <p>
                            언제나 의뢰인의 이야기에 귀를 기울이고 전문적인 도움을 드리겠습니다. 이혼소송은 각자의 상황에 맞는 해결책이 필요한 만큼 의뢰인의 상황에 적합한 최상의 결과를 만들어 드리겠습니다. 어렵고 힘든 시간을 지나 새로운 시작에 힘이 되어 드리겠습니다. 
                        </p>
                    </txt>
                </v-col>
                <v-col cols="8" sm="5" offset="4" offset-sm="7" offset-md="0">
                    <div class="lawyer-img-wrap">
                        <v-img data-aos="fade-left" src="/images/lawyer/view/문종하.png" max-width="586" :aspect-ratio="586 / 900" class="lawyer-img w-100"/>
                    </div>
                </v-col>
            </v-row>
        </page-section>
        <page-section class="lawyer-view__foot page-section--last page-section--sm">
            <info-row v-for="(item, index) in info" :key="index" :title="item.title" :topLine="index == 0">
                <info-detail v-for="(detail, index) in item.infoDetail" :key="index" :class="index !== 0 ? 'mt-10px mt-md-16px' : ''">
                    {{detail}}
                </info-detail>
            </info-row>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";
import InfoRow from "@/sets/styles/info-row.vue";
import InfoDetail from "@/sets/styles/info-detail.vue";

import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    props: {},
    components: {
        ClientPage,
        SubVisual,
        PageSection,
        InfoRow,
        InfoDetail,

        Tit,
        Txt,
    },
    data() {
        return {            
            info: [
                {
                    title : "학력",
                    infoDetail : [
                        "인하대학교 법학과 졸업",
                        "충북대학교 법학전문대학원 졸업",
                    ],
                },
                {
                    title : "약력",
                    infoDetail : [
                        "대한변호사협회등록 이혼전문변호사",
                        "건설이코노미뉴스 칼럼니스트",
                        "네이버 지식인 법률 자문 활동 ",
                        "전)법무법인 서우",
                        "전)법무법인 인성",
                        "전)봄날가정법률사무소 ",
                        "전)법무법인 혜안 ",
                        "전)중소기업 법률자문단", 
                    ],
                },
            ]
        }
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
</style>
