<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="상속" tabActive="상속 재산 분할" bg="/images/sub/visual/sv-inheritance.jpg" />
        </template>
        <page-section dark tit="상속재산분할청구" titAdd="Inheritance" style="background-image: url(/images/sub/inheritance/property/property-bg.jpg)">
            <card-primary>
                사망자(피상속인)의 재산은 공동상속인들의 공유가 됩니다. 공동상속인들은 협의를 통해 상속재산을 분할해야 하나, <br class="d-none d-xl-block">
                협의가 원만하지 않은 경우 가정법원에 분할청구가 가능합니다. 주로 공동상속인들 중에 미리 상속인으로부터 증여받은 것이 있거나, <br class="d-none d-xl-block">
                부모님을 모셨다는 등의 기여가 있다면 협의가 원만하지 않기 때문에 법원에서는 이를 고려하여 피상속인의 재산을 분할합니다.
            </card-primary>
        </page-section>
        <page-section tit="분할의 절차" titAdd="Inheritance" class="page-section-inheritance">
            <div class="card-rows">
                <card-row v-for="item in process" :key="item.txt" :image="item.image" :txt="item.txt" :caption="item.caption" />
            </div>
        </page-section>
        <page-section style="background-image: url(/images/sub/inheritance/property/property-bg2.jpg)">
            <tit-wrap-secondary dark>
                상속재산분할청구에서 주의할 점
                <template #TitAdd>
                    Inheritance
                </template>
                <template #TxtAdd>
                    상속재산분할 과정에서 자신이 망인 즉 피상속인에게 기여한 바가 있거나, 그 외에 다른 사정이 있는 경우 이를 구체적으로 주장, 입증하여야 합니다. <br class="d-none d-xl-block">
                    법원에서 알아서 합리적으로 분할해줄 것을 기여하고 감정에만 호소하여 주장할 경우에는 합리적인 결과를 얻기 어렵습니다.
                </template>
            </tit-wrap-secondary>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";
import CardRow from "@/sets/styles/card-row.vue";
import CardPrimary from "@/components/publish/parents/cards/card-primary.vue";
import TitWrapSecondary from "@/components/publish/parents/typography/tit-wrap-secondary.vue";

import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    props: {},
    components: {
        ClientPage,
        SubVisual,
        PageSection,
        CardRow,
        CardPrimary,
        TitWrapSecondary,

        Tit,
        Txt,
    },
    data() {
        return {            
            process : [
                {
                    txt : "상속재산분할은 상속인 중 1명이 공동상속인 모두를 상대로 하여 청구",
                    caption : "* 관할 법원은 상대방의 주소지 또는 부동산의 소재지 가정법원",
                    image : "/images/sub/inheritance/property/property-img.jpg",
                },
                {
                    txt : "상속재산분할청구가 있는 경우 가정법원은 일단 상속인들끼리 원만히 협의할 수 있도록 조정절차를 진행",
                    image : "/images/sub/inheritance/property/property-img2.jpg",
                },
                {
                    txt : "조정이 이루어지지 않는 경우에는 심판절차를 거쳐 법원이 재산분할의 비율을 결정",
                    image : "/images/sub/inheritance/property/property-img3.jpg",
                },
            ],

        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.page-section-inheritance{
    background-image: url(/images/sub/inheritance/inheritance-bg.svg);
    background-size: 100% auto;
    background-position: center bottom
}
</style>
